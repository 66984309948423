import React from "react"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { ServiceContainer } from "../common/ServiceContainer";
import { SectionTitle } from "../../common/SectionTitle";
import { RequestJobButtonLink } from "../common/RequestJobButtonLink";

import { ServiceContentContainer } from "../common/ServiceContentContainer";
import { MainVisual } from "../common/MainVisual";
import { Message } from "../common/Message";
import { GrayContainer } from "../common/GrayContainer";
import { Strength } from "../common/Strength";
import { WhiteContainer } from "../common/WhiteContainer";
import { Members } from "../common/Members";
import { Faq } from "../common/Faq";
import { Support } from "./common/Support";
import { Work } from "./common/Work";
import { Service } from "./common/Service";
import { Flow } from "./common/Flow";
import { ogpUrl } from "../../../utlis/ImageUtil"

/**
 * Component
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  icon: any
  imgMain: any
  imgWorks: Array<any>
  imgServices: Array<any>
  imgPack: any
  web: any
  members: Array<any>
}

/**
 * Component
 */
export const ServiceWeb: React.VFC<Props> = (props) => {

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title={props.web.ogp.title}
        description={props.web.ogp.description}
        img={ogpUrl(process.env.NODE_ENV, props.imgMain)}
      />
      <ServiceContainer paddingBottom={0}>
        <SectionTitle title={props.web.title.en} subTitle={props.web.title.ja} icon={props.icon} />
        <ServiceContentContainer>
          <MainVisual img={props.imgMain} alt={props.web.title.ja} />
          <Message title={props.web.message.title} description={props.web.message.description} />
          <GrayContainer>
            <Strength
              title={props.web.strength.title.ja}
              caption={props.web.strength.title.en}
              contents={props.web.strength.contents}
            />
            <Support
              title={props.web.support.title.ja}
              caption={props.web.support.title.en}
              contents={props.web.support.contents}
              remark={props.web.support.remark}
            />
            <Work
              title={props.web.work.title.ja}
              caption={props.web.work.title.en}
              contents={props.web.work.contents}
              imgWorks={props.imgWorks}
            />
            <Service
              title={props.web.service.title.ja}
              caption={props.web.service.title.en}
              contents={props.web.service.contents}
              remark={props.web.service.remark}
              imgServices={props.imgServices}
            />
            <Flow
              title={props.web.flow.title.ja}
              caption={props.web.flow.title.en}
              names={props.web.flow.names}
            />
            <RequestJobButtonLink />
          </GrayContainer>
          <WhiteContainer>
            <Members
              title={props.web.member.title.ja}
              caption={props.web.member.title.en}
              members={props.members}
            />
          </WhiteContainer>
          <GrayContainer>
            <Faq
              title={props.web.faq.title.ja}
              contents={props.web.faq.contents}
              url={'/question/web'}
            />
          </GrayContainer>
        </ServiceContentContainer>
      </ServiceContainer>
    </Layout>
  )
}
