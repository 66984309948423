import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  padding: 96px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 32px;
    gap: 24px;
  }
`
const Header = styled.div`
  display: flex;
  gap: 24px;
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    flex-direction: column;
  }
`
const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
  }
`
const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`
const Title = styled.h4`
  margin: auto 0 0 0;

  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #222222;
  text-align: left;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`
const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`
const Image = styled(Img)``

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  th {
    background: #E9E9E9;
    padding: 8px 16px;
    box-sizing: border-box;
    border: 1px solid #7A7A7A;

    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #222222;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  td {
    padding: 8px 16px;
    box-sizing: border-box;
    border: 1px solid #7A7A7A;

    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #222222;
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      font-size: 12px;
      line-height: 24px;
    }
  }
`

const Remark = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

type Props = {
  title: string
  caption: string
  contents: Array<any>
  remark: string
  imgServices: Array<any>
}
export const Service: React.VFC<Props> = ({title, caption, contents, remark, imgServices}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          contents.map((content, i) => {
            return (
              <Content key={i}>
                <Header>
                  <HeaderLeft>
                    <Image fluid={imgServices[i]} alt={content.title} />
                  </HeaderLeft>
                  <HeaderRight>
                    <Title>{content.title}</Title>
                    <Description>{content.description}</Description>
                  </HeaderRight>
                </Header>
                <Table>
                  <thead>
                    {
                      content.data.headers.map((v, ith) => {
                        return (
                          <th key={ith}>{v}</th>
                        )
                      })
                    }
                  </thead>
                  <tbody>
                    {
                      content.data.rows.map((row, irow) => {
                        return (
                          <tr key={irow}>
                            {
                              row.map((r, itd) => {
                                return (<td key={itd}>{r}</td>)
                              })
                            }
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Content>
            )
          })
        }
        <Remark>{remark}</Remark>
      </Contents>
    </Wrapper>
  )
}
