import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 24px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background: #FFFFFF;
`

const Title = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const Description = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

const Remark = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 28px;
  }
`

type Props = {
  title: string
  caption: string
  contents: Array<any>
  remark: string
}
export const Support: React.VFC<Props> = ({title, caption, contents, remark}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          contents.map((content, i) => {
            return (
              <Content key={i}>
                <Title>{content.title}</Title>
                <Description>{content.description}</Description>
              </Content>
            )
          })
        }
        {
        //<Remark>{remark}</Remark>
        }
      </Contents>
    </Wrapper>
  )
}
