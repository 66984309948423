import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

import IconRightArrow from "../../../../images/service/right_arrow.svg"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  gap: 40px 16px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;
    margin-top: 24px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  gap: 16px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 200px;
  height: 120px;
  box-sizing: border-box;
  background: #FFFFFF;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: auto;
    flex: 1;
    height: 94px;
  }
`
const Number = styled.span`
  font-family: 'Righteous';
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-transform: uppercase;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`
const Title = styled.h4`
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #222222;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 32px;
  }
`
const Icon = styled.img`
  width: 20px;
  &[data-shown-pc='false'] {
    @media (min-width: ${RESPONSIVE_STYLES.PC_MIN_WIDTH}px) {
      display: none;
    }
  }
  &[data-shown-tb='false'] {
    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      visibility: hidden;
    }
  }
`
type Props = {
  title: string
  caption: string
  names: Array<string>
}
export const Flow: React.VFC<Props> = ({title, caption, names}) => {

  const shownPC = [
    true,
    true,
    true,
    false,
    true,
    true,
    true,
    false,
  ]

  const shownTB = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    false,
  ]

  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          names.map((name, i) => {
            return (
              <ContentWrapper>
                <Content key={i}>
                  <Number>0{i+1}</Number>
                  <Title>{name}</Title>
                </Content>
                <Icon data-shown-pc={shownPC[i]} data-shown-tb={shownTB[i]} src={IconRightArrow} alt="右矢印" />
              </ContentWrapper>
            )
          })
        }
      </Contents>
    </Wrapper>
  )
}
