import React from "react"
import { graphql } from "gatsby"
import { ServiceWeb } from "../../../components/service/web"
import Icon from "../../../images/service/icon_service_web.svg"

export default function ServiceWebPage({ data }) {
    const {
      imgMain,
      imgWork1,
      imgWork2,
      imgWork3,
      imgService1,
      imgService2,
      imgService3,
      imgPack,
      site: { siteMetadata: { services, member } }
    } = data;

    const imgWorks = [
      imgWork1.childImageSharp.fluid,
      imgWork2.childImageSharp.fluid,
      imgWork3.childImageSharp.fluid,
    ]

    const imgServices = [
      imgService1.childImageSharp.fluid,
      imgService2.childImageSharp.fluid,
      imgService3.childImageSharp.fluid,
    ];

    const breadcrumbsData: { text: string; path: string }[] = [
      { text: services.top.title.en, path: services.top.path },
      { text: services.web.title.ja, path: services.web.path },
    ]

    return (
      <ServiceWeb
        breadcrumbsData={breadcrumbsData}
        icon={Icon}
        imgMain={imgMain.childImageSharp.fluid}
        imgWorks={imgWorks}
        imgServices={imgServices}
        imgPack={imgPack.childImageSharp.fluid}
        web={services.web}
        members={member.filter(m => m.web > 0).sort((a, b) => {
          if (a.web < b.web) return -1;
          if (a.web > b.web) return 1;
          return 0;
        })}
      />
    )
}

export const query = graphql`
  query ServiceWebQuery {
    site {
      siteMetadata {
        services {
          top {
            path
            title {
              ja
              en
            }
          }
          web {
            path
            ogp {
              title
              description
            }
            title {
              ja
              en
            }
            description
            message {
              title
              description
            }
            strength {
              title {
                ja
                en
              }
              contents {
                title
                descriptions
              }
            }
            support {
              title {
                ja
                en
              }
              contents {
                title
                description
              }
              remark
            }
            work {
              title {
                ja
                en
              }
              contents {
                title
                url
              }
            }
            service {
              title {
                ja
                en
              }
              contents {
                title
                description
                data {
                  headers
                  rows
                }
              }
              remark
            }
            flow {
              title {
                ja
                en
              }
              names
            }
            pack {
              title
              descriptions
            }
            member {
              title {
                ja
                en
              }
            }
            faq {
              title {
                ja
              }
              contents {
                question
                answer
              }
            }
          }
        }
        member {
          position
          name
          pathname
          alt
          member_index_filename
          color
          web
        }
      }
    }
    imgMain: file(relativePath: { eq: "images/service/web_thumbnail.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgWork1: file(relativePath: { eq: "images/service/nextribe.png"}) {
      childImageSharp {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgWork2: file(relativePath: { eq: "images/service/kansai_u_elementary.png"}) {
      childImageSharp {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgWork3: file(relativePath: { eq: "images/service/ompro.png"}) {
      childImageSharp {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgService1: file(relativePath: { eq: "images/service/web_service_1.png"}) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgService2: file(relativePath: { eq: "images/service/web_service_2.png"}) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgService3: file(relativePath: { eq: "images/service/web_service_3.png"}) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgPack: file(relativePath: { eq: "images/service/web_pack.png"}) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
