import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../../../AppConst"
import { TitleAndCaption } from "../../common/TitleAndCaption"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Contents = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 40px;
  margin-top: 48px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
    margin-top: 24px;
  }
  @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    grid-template-columns: 1fr;
  }
`
const Content = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  box-sizing: border-box;
  text-decoration: none;
  &:first-child {
    grid-column: 1/3;
    width: calc((100% - 24px) / 2);
    @media (max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
      grid-column: auto;
      width: 100%;
    }
  }
`
const Img = styled(Image)`
  width: 100%;
  margin-bottom: 24px;
  ${Content}:hover & {
    opacity: .7;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-bottom: 16px;
  }
`
const Title = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  color: #222222;
  ${Content}:hover & {
    opacity: .7;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
const Site = styled.p`
  font-family: 'Righteous';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #222222;
  ${Content}:hover & {
    opacity: .7;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 12px;
    line-height: 24px;
  }
`

type Props = {
  title: string
  caption: string
  contents: Array<any>
  imgWorks: Array<any>
}
export const Work: React.VFC<Props> = ({title, caption, contents, imgWorks}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} caption={caption} />
      <Contents>
        {
          contents.map((content, i) => {
            return (
              <Content href={content.url} target="_blank" rel="noopener noreferrer">
                <Img fluid={imgWorks[i]} alt={content.title} />
                <Title>{content.title}</Title>
                <Site>{content.title == "関西大学初等部" ? "school site" : "corporate site" }</Site>
              </Content>
            )
          })
        }
      </Contents>
    </Wrapper>
  )
}
